// Node packages
import React from 'react';
import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import Textfield from '@react/react-spectrum/Textfield';
import CircularProgress from '@material-ui/core/CircularProgress';
import NumberInput from '@react/react-spectrum/NumberInput';
import Checkbox from '@react/react-spectrum/Checkbox';
import Datepicker from '@react/react-spectrum/Datepicker';
import { Grid } from '@material-ui/core';

// Custom Components
import AddressForm from '../form/AddressForm';
import ContactForm from '../form/ContactForm';
import OfferForm from '../form/OfferForm';
import ConfirmationDialog from '../form/FormConfirmationDialog';

// Custom styling and utils
import './CustomCreateForm.css';
import { AddressObject } from '../../../models/memberships/Address';
import { ContactObject } from '../../../models/memberships/Contact';
import { OfferObject } from '../../../models/memberships/Offer';
import { MembershipObject } from '../../../models/memberships/Membership';
import { VIPSkuObject } from '../../../models/memberships/VIPSku';
import { createInvalidMembership } from '../../../providers/MembershipsAPI';
import ThreeYCStatus from "../../../constants/ThreeYCStatus";
import threeYCStatus from "../../../constants/ThreeYCStatus";
import Select from '@react/react-spectrum/Select';
import { getMarketSegmentOptions, getOrgType, getSubSegmentOptions, getRenewalStatusOptions } from '../../../utils/Functions/utils';
import DeployToGridContainer from '../../../components/DeployToGridContainer';
import { isLMflagDevEnabled, isLMflagEnabled, isTransferWWFlagDevEnabled, isTransferWWFlagEnabled, isRenewalStatusFlagEnabled, isRenewalStatusFlagDevEnabled } from '../../../config/FeatureFlags';

interface CustomCreateFormState {
  companyName: string;
  preferredLanguage: string;
  address: AddressObject | undefined;
  contacts: ContactObject[];
  numEligibleOffers: number;
  offers: OfferObject[];
  vipSkus: VIPSkuObject[];
  invalid: boolean | undefined;
  isLoading: boolean;
  checkValid: boolean;
  createdMembershipEligibility: string;
  createdMembership: boolean;
  createdMembershipId: string;
  createdMembershipErrorCode: string;
  createdMembershipErrorMessage: string;
  is3YCEnabled: boolean;
  isWorldWideEnabled: boolean;
  hasMinLicenseQty: boolean;
  hasMinConsumableQty: boolean;
  minLicenseCommitQty: number;
  minConsumablesCommitQty: number;
  threeYCCommitStartDate: string;
  threeYCCommitEndDate: string;
  renewalStatus: string;
  marketSegment: string;
  marketSubSegments: string[];
  newFormData: { [x: string]: any; };
  currencyCode : string;
  isLMOwner: boolean;
  isLMMember: boolean;
  linkedMembershipId: string;
  type: string;
  orgName: string;
  lmGroupId: string;
}
const nonRequiredFields = ['preferredLanguage', 'addressLine2', "phoneNumber"];
// const numberFields = ['quantity', 'numEligibleOffers'];

function getEmptyAddressObject() {
  return {
    country: '',
    region: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    phoneNumber: ''
  };
}

const initialFormData = {
  "companyProfile": {
    "address": {
      "country": "",
      "region": "",
      "city": "",
      "addressLine1": "",
      "addressLine2": "",
      "postalCode": "",
      "phoneNumber": ""
    }
  }
};

function getEmptyContactObject() {
  return {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: ''
  };
}

function getEmptyOfferObject() {
  return {
    offerId: 'OFFERID',
    productId: '',
    quantity: 1,
    quantityUnit: 'LICENSE'
  };
}

function getEmptyVIPSkusObject() {
  return {
    vipSku: 'none',
    isDisabled: false
  };
}

interface CreatedMembershipObject {
  membershipId: string;
  eligibilityStatus: string;
  ineligibleCode: string;
  ineligibleMessage: string;
  companyName: string;
  linkedContract?: {
    id?: string;
    type?: string;
    status?: string;
    orgName?: string;
    marketSegment?: string;
    migrationStatus?: string
  }
}

const mapToMembership = (formObject: CustomCreateFormState, currencyCode: string): MembershipObject => {
  const membershipObject: MembershipObject = {
    companyName: formObject.companyName,
    contacts: formObject.contacts,
    offerData: {
      numEligibleOffers: formObject.numEligibleOffers,
      additionalOffers: formObject.offers
    },
    renewalStatus: formObject.renewalStatus,
    marketSegment: formObject.marketSegment,
    marketSubSegments: formObject.marketSubSegments
  };

  if (formObject.preferredLanguage.trim() !== '') {
    membershipObject.preferredLanguage = formObject.preferredLanguage;
  }
  if (formObject.address) {
    membershipObject.address = formObject.address;
  }

  if(formObject.is3YCEnabled){
    membershipObject.commitment = {
      status: ThreeYCStatus.COMMITTED,
      startDate: formObject.threeYCCommitStartDate,
      endDate: formObject.threeYCCommitEndDate,
      minimumQuantities: []
    }
    let licenseCommitQty = 0;
    let consumableCommitQty = 0;
    if(formObject.hasMinLicenseQty){
      licenseCommitQty = formObject.minLicenseCommitQty;
    }
    if(formObject.hasMinConsumableQty){
      consumableCommitQty = formObject.minConsumablesCommitQty;
    }
    membershipObject.commitment.minimumQuantities?.push({
      offerType: "LICENSE",
      quantity: licenseCommitQty
    },
    {
      offerType: "CONSUMABLES",
      quantity: consumableCommitQty
    })
    if(membershipObject.commitment.minimumQuantities?.length === 0){
      membershipObject.commitment.minimumQuantities?.push({
        offerType:"LICENSE",
        quantity: 10
      },
      {
        offerType: "CONSUMABLES",
        quantity: 500
      });
    }
  } else {
    membershipObject.commitment = {
      status: threeYCStatus.INACTIVE
    }
  }

  if (formObject.isWorldWideEnabled) {
    const { newFormData: { country, region, city, addressLine1,  addressLine2, postalCode, phoneNumber } } = formObject || {}
    membershipObject.associatedAccounts = [{
        address : {
          country,
          region,
          city,
          addressLine1,
          addressLine2,
          postalCode,
          phoneNumber
        }
    }];

    membershipObject.offerData = {
      ...membershipObject.offerData,
      additionalOffers: [
        ...formObject.offers.map(item => ({
          ...item,
          currencyCode
        }))
      ]
    }

    membershipObject.globalSalesEnabled = true;
  }

  if(formObject.isLMOwner) {
    membershipObject.linkedMember = true;
    membershipObject.linkedContract = {
      status: "ACTIVE",
      migrationStatus: "PARTIALLY_MIGRATED",
      type: formObject.type,
      orgName: formObject.orgName,
      marketSegment: formObject.marketSegment,
    }
  }

  if (formObject.isLMMember) {
    membershipObject.linkedMember = true;
    membershipObject.linkedContract = {
      id: formObject.linkedMembershipId,
    }
  }

  return membershipObject;
};

class CustomCreateForm extends React.Component<any, CustomCreateFormState> {
  constructor(props: any) {
    super(props);
    this.state = {
      companyName: '',
      preferredLanguage: '',
      address: undefined,
      contacts: [getEmptyContactObject()],
      numEligibleOffers: 0,
      offers: [getEmptyOfferObject()],
      vipSkus: [getEmptyVIPSkusObject()],
      invalid: undefined,
      isLoading: false,
      checkValid: false,
      createdMembership: false,
      createdMembershipErrorCode: '',
      createdMembershipEligibility: '',
      createdMembershipId: '',
      createdMembershipErrorMessage: '',
      is3YCEnabled: false,
      isWorldWideEnabled: false,
      hasMinLicenseQty: true,
      hasMinConsumableQty: true,
      minLicenseCommitQty: 10,
      minConsumablesCommitQty: 500,
      threeYCCommitStartDate: "2022-07-17",
      threeYCCommitEndDate: "2025-04-28",
      renewalStatus: 'FULL',
      marketSegment: 'COM',
      marketSubSegments: [],
      newFormData: {},
      currencyCode : '',
      isLMOwner: false,
      isLMMember: false,
      linkedMembershipId: '',
      orgName: '',
      type: '',
      lmGroupId: '',
    };
  }

  validateObject = (obj: object | undefined): boolean => {
    let isValid = true;
    if (obj) {
      Object.entries(obj).forEach(([key, val]) => {
        if (typeof val === 'string' && nonRequiredFields.indexOf(key) === -1 && val.trim() === '') {
          this.setState({ invalid: true });
          isValid = false;
        }
      });
    }
    return isValid;
  };

  validateArray = (objArr: object[]): boolean => {
    for (var obj of objArr) {
      let validObject = this.validateObject(obj);
      if (!validObject) {
        return false;
      }
    }
    return true;
  };

  handleCustomCreateSubmit = () => {
    this.setState({ checkValid: true });
    const validFields = this.state.companyName !== '' && !isNaN(this.state.numEligibleOffers);
    const validAddress = this.state.address ? this.validateObject(this.state.address) : true;
    const validArrays = this.validateArray(this.state.contacts) && this.validateArray(this.state.offers) && this.validateObject(this.state.newFormData);
    const validLMOwner = this.state.isLMOwner ? this.state.orgName !== '' && this.state.type !== '' : true;
    const validLMMember = this.state.isLMMember ? this.state.linkedMembershipId !== '' : true;
    const threeYCValidationState = this.getThreeYCFormValidationState();
    const isValid = validFields && validArrays && validAddress && threeYCValidationState && validLMOwner && validLMMember;
    if (isValid) {
      this.setState({ invalid: undefined, isLoading: true });
      let customCreateFormObject = this.state;
      let membershipObject = mapToMembership(customCreateFormObject, this.state.currencyCode);
      createInvalidMembership(membershipObject).then((obj: CreatedMembershipObject) => {
        this.setState({
          companyName: '',
          preferredLanguage: '',
          address: undefined,
          contacts: [getEmptyContactObject()],
          numEligibleOffers: 0,
          offers: [getEmptyOfferObject()],
          vipSkus: [getEmptyVIPSkusObject()],
          invalid: undefined,
          isLoading: false,
          checkValid: false,
          createdMembership: true,
          createdMembershipErrorCode: obj.ineligibleCode,
          createdMembershipEligibility: obj.eligibilityStatus,
          createdMembershipId: obj.membershipId,
          createdMembershipErrorMessage: obj.ineligibleMessage,
          lmGroupId: obj?.linkedContract?.id || '',
        });
      });
    }
  };

  updateAddressState = (address: AddressObject) => {
    this.setState({ address: address });
  };

  handleAddAddress = () => {
    let newAddressObject = this.state.address ? undefined : getEmptyAddressObject();
    this.setState({
      address: newAddressObject,
      checkValid: false
    });
  };

  setIsLMOwner = (checked: boolean) => {
    this.setState({
      isLMOwner: checked
    });
  }

  setIsLMMember = (checked: boolean) => {
    this.setState({
      isLMMember: checked
    });
  }

  setOrgName = (value: string) => {
    this.setState({
      orgName: value
    });
  }

  setLinkedMembershipId = (value: string) => {
    this.setState({
      linkedMembershipId: value
    });
  }

  setOrgType = (value: string) => {
    this.setState({
      type: value
    });
  }

  handleAddContact = () => {
    let contacts = this.state.contacts;
    contacts.push(getEmptyContactObject());
    this.setState({ contacts: contacts, checkValid: false });
  };

  handleAddOffer = () => {
    let offers = this.state.offers;
    offers.push(getEmptyOfferObject());
    this.setState({ offers: offers, checkValid: false });
    this.handleAddVipSkus();
  };

  handleAddVipSkus = () => {
    let vipSkus = this.state.vipSkus;
    vipSkus.push(getEmptyVIPSkusObject());
    this.setState({ vipSkus: vipSkus, checkValid: false });
  };

  updateContactState = (contact: ContactObject, index: number) => {
    let contacts = [...this.state.contacts];
    this.setState({ contacts: contacts });
  };

  deleteContact = (index: number) => {
    let contacts = [...this.state.contacts];
    contacts.splice(index, 1);
    this.setState({ contacts: contacts });
  };

  updateOfferState = (offer: OfferObject, index: number) => {
    let offers = [...this.state.offers];
    this.setState({ offers: offers });
  };

  deleteOffer = (index: number) => {
    let offers = [...this.state.offers];
    offers.splice(index, 1);
    this.setState({ offers: offers });
  };

  refreshOfferForm = (vipSku: string, index: number) => {
    let vipskus = this.state.vipSkus;

    if (vipSku === 'none') {
      vipskus[index].vipSku = vipSku
      vipskus[index].isDisabled = false
      this.setState({ vipSkus: vipskus });
    } else {
      vipskus[index].vipSku = vipSku
      vipskus[index].isDisabled = true
      this.setState({ vipSkus: vipskus });
    }
  }

  handleDismissDialog = () => {
    this.setState({
      createdMembership: false
    });
  };

  threeYCCommitmentPeriodChange = (valueText: string |{start: string, end: string}) => {
    if(typeof valueText != 'string'){
      this.setState({
        threeYCCommitStartDate: valueText.start,
        threeYCCommitEndDate: valueText.end
      })
    }
  }
  
  licenseCommitQtyChange = (value: number | null) => {
    if(value){
      this.setState({
        minLicenseCommitQty: value
      })
    }
  }

  consumableCommitQtyChange = (value: number | null) => {
    if(value){
      this.setState({
        minConsumablesCommitQty: value
      })
    }
  }

  set3YCStatusChange = (checked: boolean) => {
    this.setState({
      is3YCEnabled: checked
    })
  }

  setWorldWideChange = (checked: boolean) => {
    this.setState({
      isWorldWideEnabled: checked
    })

    if (checked) {
      this.setState({ vipSkus: [getEmptyVIPSkusObject()], offers: [getEmptyOfferObject()], numEligibleOffers: 0 })
    }
  }

  hasMinLicenseQtyChange = (checked: boolean) => {
    this.setState({
      hasMinLicenseQty: checked
    })
  }

  setNewFormData = (data: { [x: string]: any; }) => {
    this.setState({
      newFormData: data
    })
  }

  hasMinConsumableQtyChange = (checked: boolean) => {
    this.setState({
      hasMinConsumableQty: checked
    })
  }

  getThreeYCFormValidationState = (): boolean => {
    return this.state.is3YCEnabled ? this.getCommitQtyCheckboxValidationState() : true;
  }

  getCommitQtyCheckboxValidationState = (): boolean => {
    return this.state.hasMinConsumableQty || this.state.hasMinLicenseQty;
  }

  render(): React.ReactNode {
    return (
      <div>
        <ConfirmationDialog
          createdMembership={this.state.createdMembership}
          lmGroupId={this.state.lmGroupId}
          createdMembershipId={this.state.createdMembershipId}
          createdMembershipEligibility={this.state.createdMembershipEligibility}
          createdMembershipErrorCode={this.state.createdMembershipErrorCode}
          createdMembershipErrorMessage={this.state.createdMembershipErrorMessage}
          handleDismissDialog={this.handleDismissDialog}
        />
        <div className='custom-create-heading-container'>
          <Heading variant='subtitle1'>Custom Create</Heading>
          {this.state.isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Button variant='cta' onClick={() => this.handleCustomCreateSubmit()}>
              Create
            </Button>
          )}
        </div>
        <Heading variant='subtitle3'>Created membership ID may or may not be eligible.</Heading>
        <br />
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <p className='custom-create-field-label'>Company Name</p>
            <Textfield
              value={this.state.companyName}
              validationState={this.state.invalid && this.state.companyName.trim() === '' ? 'invalid' : undefined}
              className='custom-create-textfield'
              onChange={value =>
                this.setState({
                  companyName: value
                })
              }
            />
          </Grid>
          <Grid item md={3} xs={6}>
            <p className='custom-create-field-label'>Preferred Language (optional)</p>
            <Textfield
              className='custom-create-textfield'
              value={this.state.preferredLanguage}
              onChange={value => this.setState({ preferredLanguage: value })}
            />
          </Grid>
          {(isRenewalStatusFlagDevEnabled() || isRenewalStatusFlagEnabled()) && (
          <Grid item md={3} xs={6}>
          <p className='quick-create-field-label'>Renewal Status</p>
              <Select
                aria-label='Renewal Status'
                placeholder="Renewal Status"
                value={this.state.renewalStatus}
                options={getRenewalStatusOptions()}
                onSelect={value => {
                  this.setState({ renewalStatus: value })
                }}
              />
          </Grid>
          )}
          <Grid item md={3} xs={6}>
          <p className='quick-create-field-label'>Market Segment</p>
              <Select
                aria-label='Market segments'
                placeholder="Market Segments"
                value={this.state.marketSegment}
                options={getMarketSegmentOptions()}
                onSelect={value => {
                  this.setState({ marketSegment: value })
                }}
              />
          </Grid>
          {this.state.marketSegment === 'EDU' && (
        <Grid item md={3} xs={12}>
          <p className='quick-create-field-label'>Sub Segments</p>
          <div className='quick-create-number-select-container'>
            <Select
              multiple
              aria-label='Sub Segments'
              placeholder="Sub Segments"
              value={this.state.marketSubSegments}
              options={getSubSegmentOptions(this.state.marketSubSegments)}
              onSelect={value => {
                const marketSubSegments = (value as unknown) as string[]
                this.setState({ marketSubSegments })
              }}
            />
          </div>
        </Grid>
      )}
          
        </Grid>
        <br />
        <div className='custom-create-heading-container'>
          <Heading variant='subtitle2'>Address</Heading>
          <Button onClick={() => this.handleAddAddress()}>{this.state.address ? 'Remove' : 'Add'}</Button>
        </div>
        {this.state.address ? (
          <AddressForm
            address={this.state.address}
            checkValid={this.state.checkValid}
            updateAddressState={this.updateAddressState}
          />
        ) : (
          <p>Distributor address will be used. Click <b>Add</b> to specify an address.</p>
        )}
        <br />
        <div className='custom-create-heading-container'>
          <Heading variant='subtitle2'>Contacts</Heading>
          <Button onClick={() => this.handleAddContact()}>Add</Button>
        </div>
        {this.state.contacts.map((key, index) => {
          return (
            <ContactForm
              key={index}
              contact={key}
              index={index}
              checkValid={this.state.checkValid}
              updateContactState={this.updateContactState}
              deleteContact={this.deleteContact}
            />
          );
        })}
        {this.state.contacts.length === 0 && <p>No contacts will be added.</p>}
        <br />
        <div className='custom-create-heading-container'>
          <Heading variant='subtitle2'>Offers</Heading>
          <Button onClick={() => this.handleAddOffer()}>Add</Button>
        </div>
        {this.state.offers.map((offer, index) => {

          return (
            <OfferForm
              key={index}
              offer={offer}
              index={index}
              disableOffers={this.state.isWorldWideEnabled}
              vipSku={this.state.vipSkus[index]}
              checkValid={this.state.checkValid}
              updateOfferState={this.updateOfferState}
              deleteOffer={this.deleteOffer}
              refreshOfferForm={this.refreshOfferForm}
            />
          );
        })}
        {this.state.offers.length === 0 && <p>No specified offers will be added. Click <b>Add</b> to add offer IDs or select the number of eligible offers to add below.</p>}
        <Grid item md={3} xs={6}>
          <p className='custom-create-field-label'>Number of Additional Eligible Offers (chosen at random)</p>
          <div className='custom-create-number-input-container'>
            <NumberInput
              min={0}
              max={100}
              value={this.state.numEligibleOffers}
              disabled={this.state.isWorldWideEnabled}
              onChange={value => {
                if (value) {
                  this.setState({ numEligibleOffers: value });
                }
              }}
            />
          </div>
        </Grid>
        <div>
          <br></br>     
          {(isLMflagDevEnabled() || isLMflagEnabled()) && (
            <>
              <Heading variant='subtitle2'>Linked Membership</Heading>
              <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>
                    <Checkbox
                      checked={this.state.isLMOwner}
                      onChange={(checked) => this.setIsLMOwner(checked)}
                      disabled={this.state.is3YCEnabled || this.state.isLMMember}
                    >
                      Linked Membership Owner
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      checked={this.state.isLMMember}
                      onChange={(checked) => this.setIsLMMember(checked)}
                      disabled={this.state.isLMOwner || this.state.is3YCEnabled}
                    >
                      Linked Membership Member
                    </Checkbox>
                  </div>

                  {this.state.isLMOwner && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ paddingLeft: 20, paddingRight: 20, width: 300 }}>
                        <p className='quick-create-field-label'>Organization Name</p>
                        <Textfield 
                          placeholder='Organization Name'
                          className='quick-create-textfield'
                          value={this.state.orgName}
                          validationState={this.state.invalid && this.state.orgName.trim() === '' ? 'invalid' : undefined}
                          onChange={value => this.setOrgName(value)}
                        />
                      </div>
                      <div>
                        <p className='quick-create-field-label'>Type</p>
                        <Select
                          aria-label='Type'
                          placeholder="Type"
                          value={this.state.type}
                          options={getOrgType()}
                          onSelect={value => {
                            this.setOrgType(value)
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.isLMMember && (
                    <div>
                      <p className='quick-create-field-label'>Linked Membership Group ID</p>
                      <Textfield 
                        className='quick-create-textfield'
                        value={this.state.linkedMembershipId}
                        validationState={this.state.invalid && this.state.linkedMembershipId.trim() === '' ? 'invalid' : undefined}
                        onChange={value => this.setLinkedMembershipId(value)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <Heading variant='subtitle2'>3YC Info</Heading>
          <Grid container spacing={1}>
            <Grid item md={2} xs={6}>
            <Checkbox 
              defaultChecked={this.state.is3YCEnabled}
              disabled={this.state.isLMOwner || this.state.isLMMember}
              onChange={this.set3YCStatusChange}>3YC Enabled
            </Checkbox>
            {this.state.is3YCEnabled ? (<div>
            <Checkbox defaultChecked={this.state.hasMinLicenseQty} 
              onChange={this.hasMinLicenseQtyChange} 
              invalid={!this.getCommitQtyCheckboxValidationState()}>Add Custom License Commit Qty</Checkbox>
            <Checkbox defaultChecked={this.state.hasMinConsumableQty} 
              onChange={this.hasMinConsumableQtyChange} 
              invalid={!this.getCommitQtyCheckboxValidationState()}>Add Custom Consumable Commit Qty</Checkbox>
            </div>) : (null)}
            </Grid>
            {this.state.is3YCEnabled ? (<>
            {this.state.hasMinLicenseQty ? (<Grid item md={3} xs={6}>
              <p>Minimum License Commit Quantity</p>
              <NumberInput min={10} 
                defaultValue={this.state.minLicenseCommitQty}
                onChange={this.licenseCommitQtyChange}></NumberInput>
            </Grid>) : (null)}
            {this.state.hasMinConsumableQty ? (<Grid item md={3} xs={6}>
              <p>Minimum Consumable Commit Quantity</p>
              <NumberInput min={1} 
                defaultValue={this.state.minConsumablesCommitQty}
                onChange={this.consumableCommitQtyChange}></NumberInput>
            </Grid>) : (null)}          
            <Grid item md={3} xs={6}>
              <p>Commitment Period</p>
              <Datepicker selectionType='range'
              value={[this.state.threeYCCommitStartDate, this.state.threeYCCommitEndDate]}
              onChange={this.threeYCCommitmentPeriodChange}></Datepicker>
            </Grid>
            </>) : (null)}          
            <br />
            <br />
            </Grid>
            {(isTransferWWFlagDevEnabled() || isTransferWWFlagEnabled()) && (
              <div>
                <Heading variant='subtitle2'>Deployment</Heading>
                <Checkbox 
                  defaultChecked={this.state.isWorldWideEnabled}
                  onChange={this.setWorldWideChange}>Enable WorldWide
                </Checkbox>
                {this.state.isWorldWideEnabled
                  ? (
                    <div style={{ width: 400}}>
                      <DeployToGridContainer
                        formData={initialFormData}
                        setNewFormData={this.setNewFormData}
                        newFormData={this.state.newFormData}
                        disabledFields={[]}
                        checkValid={this.state.checkValid}
                      />
                      <Heading variant='subtitle2'>Currency Code</Heading>
                      <Grid item md={3} xs={6}>
                        <Textfield
                          className='custom-create-textfield'
                          value={this.state.currencyCode}
                          onChange={value => this.setState({ currencyCode: value })}
                        />
                      </Grid>
                
                    </div>
                  )
                  : null
                }
              </div>
            )}
            </div> 
      </div>
    );
  }
}

export default CustomCreateForm;
